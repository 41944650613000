<template>
    <div>
        <div class="header-wrapper">
            <div class="header-inner">
                <div @click="gotoPage(logo_link)" class="ID-logo"></div>
                <div class="menu-items-wrapper">
                    <div v-if="show_home" @click="gotoPage(home_link)" class="menu-item tab-home"><i class="fa fa-home" aria-hidden="true"></i></div>
                    <div v-if="show_faqs"  @click="gotoPage('/faqs.html')" class="menu-item tab-faq">FAQs</div>
                    <div v-if="show_manage" class="menu-item tab-manage">Manage My Booking</div>
                    <div v-if="show_languages" class="menu-item tab-lang">
                        <span class="lang-text">{{lang}}</span>
                        <div class="lang-flag" :style="flagImage"></div>
                    </div>
                    <div v-if="show_basket" class="menu-item tab-basket" @click="toggleBasket('toggle')" tabindex="0">
                        <span class="basket-number">{{basket_number}}</span>
                        <img class="basket-icon" src="../assets/images/basket.svg">
                        <span class="basket-text">Basket</span>
                    </div>
                    <div v-if="show_login" class="menu-item tab-login">Login</div>
                    <div v-if="show_signup" class="menu-item tab-signup">Sign up</div>
                    <div v-if="show_home || show_faqs || show_manage || show_languages || show_basket || show_login || show_signup" class="menu-item tab-mobile-menu"  @click="showMobileMenu = !showMobileMenu" @focusout="showMobileMenu = false" tabindex="0">
                       <i class="fa fa-bars"></i>
                           <transition name="fade">
                        <div  v-show="showMobileMenu" class="menu-items_mobile-wrapper">
                            <div v-if="show_faqs" @click="gotoPage('/faqs.html')" class="menu-item-mobile tab-faq"><span>FAQs</span><i class="fa fa-chevron-right"></i></div>
                            <div v-if="show_manage" class="menu-item-mobile tab-manage"><span>Manage My Booking</span><i class="fa fa-chevron-right"></i></div>
                            <div v-if="show_login" class="menu-item-mobile tab-login"><span>Login</span><i class="fa fa-chevron-right"></i></div>
                            <div v-if="show_signup" class="menu-item-mobile tab-signup"><span>Sign up</span><i class="fa fa-chevron-right"></i></div>
                            <!--<div class="menu-item-mobile tab-lang">-->
                            <!--    <div v-for="lng in languages" :key="lng">-->
                            <!--        <div v-if="lng !== params.lang" class="lang-flag" :style="getFlagImage(lng)" @click="changeLang(lng)"></div>-->
                            <!--    </div>-->
                            <!--</div>-->
                        </div>
                         </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Header",
    props: {
        logo_link: undefined,
        home_link: undefined,
        lang: undefined,
        show_home: undefined,
        show_faqs: undefined,
        show_manage: undefined,
        show_languages: undefined,
        show_basket: undefined,
        show_login: undefined,
        show_signup: undefined,
        basket_number: undefined
    },
    data() {
        return {
            showMobileMenu: false,
            showBasket: false,
            showBasketOnHeader: true,
        }
        },
    computed: {
            flagImage() {
            return `background-image: url(${require(`../assets/flags/` + this.lang + `.png`)});`;
        }
    },
    methods: {
        toggleBasket(val) {
            this.emitter.emit('toggleBasket', val);
        },
        gotoPage(url) {
            window.location.href = url;
        },
    }
};
</script>


<style scoped lang="scss">

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0
    }


    $dark-color: #fff;

    .header-wrapper {
        border-bottom: 0px solid $dark-color;
        background: #09B7B4;
        margin: 0;
        padding: 0;
        height: 60px;
        width: 100%;
        float: left;
        position: relative;
        box-sizing: content-box;
        .header-inner {
            height: 100%;
            max-width: 1280px;
            margin: 0 auto;
        }
        .ID-logo {
            background-repeat: no-repeat;
            background-size: contain;
            width: 200px;
            height: 52px;
            margin-top: 4px;
            margin-right: 0;
            margin-left: 8px;
            float: left;
            position: absolute;
            background-image: url("../assets/images/logo.png");
            cursor: pointer;
        }
        .menu-items-wrapper {
            color: $dark-color;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            user-select: none;
            .menu-item {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding: 0 15px;
                border-right: 0px solid #ddd;
                transition: 0.2s ease-in-out;
                &:hover {
                    background-color: rgba(54, 77, 91, 0.09);
                }
                &:first-child {
                    border-left: 0px solid #ddd;
                }
                .fa-home {
                    font-size: 20px;
                }
                .lang-text {
                    border-radius: 20px;
                    padding: 1px 10px;
                    background: #000;
                    color: #fff;
                    margin-right: 10px;
                }
                .lang-flag {
                    width: 32px;
                    height: 32px;

                }

                .basket-icon {
                    width: 37px;
                    height: 38px;
                }
                .basket-text {
                    margin-left: 10px;
                    @media screen and (max-width: 1280px) {
                        display: none;
                    }
                }
                .basket-number {
                    position: absolute;
                    left: 0px;
                    width: 64px;
                    text-align: center;
                    top: 12px;
                    font-weight: bold;
                    font-size: 16px;
                    color: #F1A706;
                }

            }
            .tab-basket {
                position: relative;
                padding-left: 10px;
                margin-left: 0px;
            }
            .tab-mobile-menu {
                display: none;
            }

            .menu-items_mobile-wrapper {
                position: absolute;
                top: 60px;
                right: 0px;
                display: flex;
                flex-direction: column;
                z-index: 99999;
                background: #1C315C;

            }

            .menu-items_mobile-wrapper {
                width: 100%;
                max-width: 320px;
                box-shadow: 0 8px 8px 1px rgba(0,0,0,0.5);
                .menu-item-mobile {
                    box-sizing: border-box;
                    padding: 15px;
                    text-align: left;
                    border-bottom: 1px solid rgba(0,0,0,0.2);
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .menu-item-mobile.tab-lang {
                    padding: 8px 15px;
                    justify-content: flex-start;
                    .lang-flag {
                        margin-right: 15px;
                    }
                }


                .menu-item-mobile.tab-faq,
                .menu-item-mobile.tab-manage,
                .menu-item-mobile.tab-lang,
                .menu-item-mobile.tab-login,
                .menu-item-mobile.tab-signup {
                    display: none;
                }
            }

            /* header compacting */
            @media screen and (max-width: 959px) {
                .tab-mobile-menu {
                    padding: 0 20px;
                    display: flex;
                    &:focus {
                        outline: none;
                    }
                }
                .tab-lang,
                .tab-login,
                .tab-signup {
                    display: none;

                }
                .menu-items_mobile-wrapper {
                    .menu-item-mobile.tab-lang,
                    .menu-item-mobile.tab-login,
                    .menu-item-mobile.tab-signup {
                        display: flex;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                .tab-home,
                .tab-faq,
                .tab-manage {
                    display: none;
                }
                .menu-items_mobile-wrapper {
                    .menu-item-mobile.tab-faq,
                    .menu-item-mobile.tab-manage {
                        display: flex;
                    }
                }
            }
        }
    }
</style>