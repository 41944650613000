import { createApp } from 'vue';
import App from './Startpage.vue';

import mitt from 'mitt';
const emitter = mitt();

import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import { VuelidatePlugin } from '@vuelidate/core';

import { VueCookieNext } from 'vue-cookie-next';

let app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.use(PrimeVue);
app.use(VuelidatePlugin);
app.component('Dialog', Dialog);
app.use(VueCookieNext);
app.mount('#app');
